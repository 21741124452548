import React from "react";

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      chatRooms: null,
    };
  }

  // Init
  async componentDidMount() {
    if (this.props.messages) {
    }
  }

  render() {
    return <></>;
  }
}

export default Chat;
