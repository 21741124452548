import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { updatePharmacy } from "../graphql/mutations";
import { UserContext } from "../App";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import config from "../aws-exports";

Amplify.configure(config);

const InformationDetail = (props) => {
  const { context, setContext } = useContext(UserContext);
  const [informationDetail, setInformationsDetail] = useState(
    props.informationDetail
  );

  useEffect(() => {
    if (context) {
      updateReadFlg();
    }
  }, [context]);

  const updateReadFlg = async () => {
    try {
      let updateInput = context.data;
      delete updateInput.users;
      delete updateInput.chatRooms;
      delete updateInput.pharmacyGroup;
      delete updateInput.updatedAt;
      delete updateInput.createdAt;
      if (
        updateInput.readInformationsIds &&
        updateInput.readInformationsIds.length > 0
      ) {
        if (
          !updateInput.readInformationsIds.includes(props.informationDetail.id)
        ) {
          updateInput.readInformationsIds = [
            ...updateInput.readInformationsIds,
            props.informationDetail.id,
          ];
        }
      } else {
        updateInput.readInformationsIds = [props.informationDetail.id];
      }
      // GraphQL
      await API.graphql(
        graphqlOperation(updatePharmacy, { input: updateInput })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const dateFormat = (date) => {
    if (date == "") return "不明";
    var _date = new Date(date);
    var y = _date.getFullYear();
    var m = _date.getMonth() + 1;
    var d = ("0" + _date.getDate()).slice(-2);
    var h = ("0" + _date.getHours()).slice(-2);
    var mm = ("0" + _date.getMinutes()).slice(-2);
    return `${y}年${m}月${d}日 ${h}時${mm}分`;
  };

  return (
    <>
      <div className="informationDetail">
        <h3>{informationDetail.title}</h3>
        <div>{dateFormat(informationDetail.createdAt)}</div>
        <p className="content">{informationDetail.message}</p>
      </div>
    </>
  );
};

export default InformationDetail;
