import React from "react";

const Icon = (props) => {
  return (
    <svg
      id="グループ_9"
      data-name="グループ 9"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width}
      height={props.height}
      className={props.className}
      viewBox="0 0 175.668 175.668"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="長方形_222"
            data-name="長方形 222"
            width="175.668"
            height="175.668"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="グループ_8" data-name="グループ 8" clipPath="url(#clipPath)">
        <g id="グループ_7" data-name="グループ 7">
          <g id="グループ_6" data-name="グループ 6" clipPath="url(#clipPath)">
            <g id="グループ_5" data-name="グループ 5" opacity="0.6">
              <g id="グループ_4" data-name="グループ 4">
                <g
                  id="グループ_3"
                  data-name="グループ 3"
                  clipPath="url(#clipPath)"
                >
                  <rect
                    id="長方形_2"
                    data-name="長方形 2"
                    width="42.403"
                    height="6.057"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_3"
                    data-name="長方形 3"
                    width="6.058"
                    height="6.057"
                    transform="translate(84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_4"
                    data-name="長方形 4"
                    width="12.115"
                    height="6.057"
                    transform="translate(109.035)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_5"
                    data-name="長方形 5"
                    width="42.403"
                    height="6.057"
                    transform="translate(133.265)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_6"
                    data-name="長方形 6"
                    width="6.058"
                    height="6.058"
                    transform="translate(0 6.058)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_7"
                    data-name="長方形 7"
                    width="6.058"
                    height="6.058"
                    transform="translate(36.345 6.058)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_8"
                    data-name="長方形 8"
                    width="6.058"
                    height="6.058"
                    transform="translate(48.46 6.058)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_9"
                    data-name="長方形 9"
                    width="6.058"
                    height="6.058"
                    transform="translate(60.575 6.058)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_10"
                    data-name="長方形 10"
                    width="6.058"
                    height="6.058"
                    transform="translate(84.805 6.058)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_11"
                    data-name="長方形 11"
                    width="6.058"
                    height="6.058"
                    transform="translate(109.035 6.058)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_12"
                    data-name="長方形 12"
                    width="6.058"
                    height="6.058"
                    transform="translate(121.15 6.058)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_13"
                    data-name="長方形 13"
                    width="6.058"
                    height="6.058"
                    transform="translate(133.265 6.058)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_14"
                    data-name="長方形 14"
                    width="6.057"
                    height="6.058"
                    transform="translate(169.61 6.058)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_15"
                    data-name="長方形 15"
                    width="6.058"
                    height="6.058"
                    transform="translate(0 12.115)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_16"
                    data-name="長方形 16"
                    width="18.173"
                    height="6.058"
                    transform="translate(12.115 12.115)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_17"
                    data-name="長方形 17"
                    width="6.058"
                    height="6.058"
                    transform="translate(36.345 12.115)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_18"
                    data-name="長方形 18"
                    width="6.058"
                    height="6.058"
                    transform="translate(66.633 12.115)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_19"
                    data-name="長方形 19"
                    width="42.403"
                    height="6.058"
                    transform="translate(84.805 12.115)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_20"
                    data-name="長方形 20"
                    width="6.058"
                    height="6.058"
                    transform="translate(133.265 12.115)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_21"
                    data-name="長方形 21"
                    width="18.173"
                    height="6.058"
                    transform="translate(145.38 12.115)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_22"
                    data-name="長方形 22"
                    width="6.057"
                    height="6.058"
                    transform="translate(169.61 12.115)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_23"
                    data-name="長方形 23"
                    width="6.058"
                    height="6.058"
                    transform="translate(0 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_24"
                    data-name="長方形 24"
                    width="18.173"
                    height="6.058"
                    transform="translate(12.115 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_25"
                    data-name="長方形 25"
                    width="6.058"
                    height="6.058"
                    transform="translate(36.345 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_26"
                    data-name="長方形 26"
                    width="12.115"
                    height="6.058"
                    transform="translate(54.518 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_27"
                    data-name="長方形 27"
                    width="6.058"
                    height="6.058"
                    transform="translate(72.69 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_28"
                    data-name="長方形 28"
                    width="6.058"
                    height="6.058"
                    transform="translate(84.805 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_29"
                    data-name="長方形 29"
                    width="6.058"
                    height="6.058"
                    transform="translate(96.92 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_30"
                    data-name="長方形 30"
                    width="12.115"
                    height="6.058"
                    transform="translate(109.035 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_31"
                    data-name="長方形 31"
                    width="6.058"
                    height="6.058"
                    transform="translate(133.265 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_32"
                    data-name="長方形 32"
                    width="18.173"
                    height="6.058"
                    transform="translate(145.38 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_33"
                    data-name="長方形 33"
                    width="6.057"
                    height="6.058"
                    transform="translate(169.61 18.173)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_34"
                    data-name="長方形 34"
                    width="6.058"
                    height="6.057"
                    transform="translate(0 24.23)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_35"
                    data-name="長方形 35"
                    width="18.173"
                    height="6.057"
                    transform="translate(12.115 24.23)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_36"
                    data-name="長方形 36"
                    width="6.058"
                    height="6.057"
                    transform="translate(36.345 24.23)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_37"
                    data-name="長方形 37"
                    width="6.058"
                    height="6.057"
                    transform="translate(54.518 24.23)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_38"
                    data-name="長方形 38"
                    width="6.058"
                    height="6.057"
                    transform="translate(72.69 24.23)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_39"
                    data-name="長方形 39"
                    width="6.058"
                    height="6.057"
                    transform="translate(96.92 24.23)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_40"
                    data-name="長方形 40"
                    width="6.058"
                    height="6.057"
                    transform="translate(109.035 24.23)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_41"
                    data-name="長方形 41"
                    width="6.058"
                    height="6.057"
                    transform="translate(133.265 24.23)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_42"
                    data-name="長方形 42"
                    width="18.173"
                    height="6.057"
                    transform="translate(145.38 24.23)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_43"
                    data-name="長方形 43"
                    width="6.057"
                    height="6.057"
                    transform="translate(169.61 24.23)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_44"
                    data-name="長方形 44"
                    width="6.058"
                    height="6.058"
                    transform="translate(0 30.288)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_45"
                    data-name="長方形 45"
                    width="6.058"
                    height="6.058"
                    transform="translate(36.345 30.288)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_46"
                    data-name="長方形 46"
                    width="6.058"
                    height="6.058"
                    transform="translate(48.46 30.288)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_47"
                    data-name="長方形 47"
                    width="30.288"
                    height="6.058"
                    transform="translate(60.575 30.288)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_48"
                    data-name="長方形 48"
                    width="18.173"
                    height="6.058"
                    transform="translate(109.035 30.288)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_49"
                    data-name="長方形 49"
                    width="6.058"
                    height="6.058"
                    transform="translate(133.265 30.288)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_50"
                    data-name="長方形 50"
                    width="6.057"
                    height="6.058"
                    transform="translate(169.61 30.288)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_51"
                    data-name="長方形 51"
                    width="42.403"
                    height="6.058"
                    transform="translate(0 36.345)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_52"
                    data-name="長方形 52"
                    width="6.058"
                    height="6.058"
                    transform="translate(48.46 36.345)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_53"
                    data-name="長方形 53"
                    width="6.058"
                    height="6.058"
                    transform="translate(60.575 36.345)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_54"
                    data-name="長方形 54"
                    width="6.058"
                    height="6.058"
                    transform="translate(72.69 36.345)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_55"
                    data-name="長方形 55"
                    width="6.058"
                    height="6.058"
                    transform="translate(84.805 36.345)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_56"
                    data-name="長方形 56"
                    width="6.058"
                    height="6.058"
                    transform="translate(96.92 36.345)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_57"
                    data-name="長方形 57"
                    width="6.058"
                    height="6.058"
                    transform="translate(109.035 36.345)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_58"
                    data-name="長方形 58"
                    width="6.058"
                    height="6.058"
                    transform="translate(121.15 36.345)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_59"
                    data-name="長方形 59"
                    width="42.403"
                    height="6.058"
                    transform="translate(133.265 36.345)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_60"
                    data-name="長方形 60"
                    width="18.173"
                    height="6.058"
                    transform="translate(48.46 42.403)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_61"
                    data-name="長方形 61"
                    width="36.345"
                    height="6.058"
                    transform="translate(78.748 42.403)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_62"
                    data-name="長方形 62"
                    width="24.23"
                    height="6.057"
                    transform="translate(24.23 48.46)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_63"
                    data-name="長方形 63"
                    width="6.058"
                    height="6.057"
                    transform="translate(54.518 48.46)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_64"
                    data-name="長方形 64"
                    width="24.23"
                    height="6.057"
                    transform="translate(78.748 48.46)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_65"
                    data-name="長方形 65"
                    width="6.058"
                    height="6.057"
                    transform="translate(109.035 48.46)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_66"
                    data-name="長方形 66"
                    width="12.115"
                    height="6.057"
                    transform="translate(133.265 48.46)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_67"
                    data-name="長方形 67"
                    width="6.058"
                    height="6.057"
                    transform="translate(163.553 48.46)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_68"
                    data-name="長方形 68"
                    width="18.173"
                    height="6.058"
                    transform="translate(6.058 54.518)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_69"
                    data-name="長方形 69"
                    width="6.058"
                    height="6.058"
                    transform="translate(30.288 54.518)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_70"
                    data-name="長方形 70"
                    width="24.23"
                    height="6.058"
                    transform="translate(60.575 54.518)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_71"
                    data-name="長方形 71"
                    width="12.115"
                    height="6.058"
                    transform="translate(90.863 54.518)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_72"
                    data-name="長方形 72"
                    width="12.115"
                    height="6.058"
                    transform="translate(115.093 54.518)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_73"
                    data-name="長方形 73"
                    width="18.173"
                    height="6.058"
                    transform="translate(133.265 54.518)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_74"
                    data-name="長方形 74"
                    width="12.115"
                    height="6.058"
                    transform="translate(163.553 54.518)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_75"
                    data-name="長方形 75"
                    width="18.173"
                    height="6.057"
                    transform="translate(12.115 60.575)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_76"
                    data-name="長方形 76"
                    width="6.058"
                    height="6.057"
                    transform="translate(36.345 60.575)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_77"
                    data-name="長方形 77"
                    width="12.115"
                    height="6.057"
                    transform="translate(48.46 60.575)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_78"
                    data-name="長方形 78"
                    width="18.173"
                    height="6.057"
                    transform="translate(78.748 60.575)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_79"
                    data-name="長方形 79"
                    width="6.058"
                    height="6.057"
                    transform="translate(102.978 60.575)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_80"
                    data-name="長方形 80"
                    width="12.115"
                    height="6.057"
                    transform="translate(121.15 60.575)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_81"
                    data-name="長方形 81"
                    width="6.057"
                    height="6.057"
                    transform="translate(145.38 60.575)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_82"
                    data-name="長方形 82"
                    width="6.057"
                    height="6.057"
                    transform="translate(169.61 60.575)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_83"
                    data-name="長方形 83"
                    width="6.058"
                    height="6.058"
                    transform="translate(12.115 66.633)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_84"
                    data-name="長方形 84"
                    width="24.23"
                    height="6.058"
                    transform="translate(42.403 66.633)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_85"
                    data-name="長方形 85"
                    width="6.058"
                    height="6.058"
                    transform="translate(84.805 66.633)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_86"
                    data-name="長方形 86"
                    width="6.058"
                    height="6.058"
                    transform="translate(127.208 66.633)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_87"
                    data-name="長方形 87"
                    width="12.115"
                    height="6.058"
                    transform="translate(145.38 66.633)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_88"
                    data-name="長方形 88"
                    width="12.115"
                    height="6.058"
                    transform="translate(163.553 66.633)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_89"
                    data-name="長方形 89"
                    width="6.058"
                    height="6.057"
                    transform="translate(12.115 72.69)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_90"
                    data-name="長方形 90"
                    width="12.115"
                    height="6.057"
                    transform="translate(30.288 72.69)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_91"
                    data-name="長方形 91"
                    width="12.115"
                    height="6.057"
                    transform="translate(54.518 72.69)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_92"
                    data-name="長方形 92"
                    width="12.115"
                    height="6.057"
                    transform="translate(84.805 72.69)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_93"
                    data-name="長方形 93"
                    width="6.058"
                    height="6.057"
                    transform="translate(115.093 72.69)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_94"
                    data-name="長方形 94"
                    width="6.058"
                    height="6.057"
                    transform="translate(139.323 72.69)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_95"
                    data-name="長方形 95"
                    width="6.058"
                    height="6.057"
                    transform="translate(151.438 72.69)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_96"
                    data-name="長方形 96"
                    width="6.057"
                    height="6.057"
                    transform="translate(169.61 72.69)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_97"
                    data-name="長方形 97"
                    width="18.173"
                    height="6.058"
                    transform="translate(18.173 78.748)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_98"
                    data-name="長方形 98"
                    width="6.058"
                    height="6.058"
                    transform="translate(42.403 78.748)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_99"
                    data-name="長方形 99"
                    width="6.058"
                    height="6.058"
                    transform="translate(54.518 78.748)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_100"
                    data-name="長方形 100"
                    width="12.115"
                    height="6.058"
                    transform="translate(72.69 78.748)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_101"
                    data-name="長方形 101"
                    width="12.115"
                    height="6.058"
                    transform="translate(90.863 78.748)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_102"
                    data-name="長方形 102"
                    width="6.058"
                    height="6.058"
                    transform="translate(133.265 78.748)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_103"
                    data-name="長方形 103"
                    width="6.057"
                    height="6.058"
                    transform="translate(145.38 78.748)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_104"
                    data-name="長方形 104"
                    width="6.058"
                    height="6.058"
                    transform="translate(157.495 78.748)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_105"
                    data-name="長方形 105"
                    width="6.057"
                    height="6.058"
                    transform="translate(169.61 78.748)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_106"
                    data-name="長方形 106"
                    width="6.058"
                    height="6.057"
                    transform="translate(6.058 84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_107"
                    data-name="長方形 107"
                    width="12.115"
                    height="6.057"
                    transform="translate(18.173 84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_108"
                    data-name="長方形 108"
                    width="6.058"
                    height="6.057"
                    transform="translate(36.345 84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_109"
                    data-name="長方形 109"
                    width="12.115"
                    height="6.057"
                    transform="translate(48.46 84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_110"
                    data-name="長方形 110"
                    width="6.058"
                    height="6.057"
                    transform="translate(66.633 84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_111"
                    data-name="長方形 111"
                    width="6.058"
                    height="6.057"
                    transform="translate(90.863 84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_112"
                    data-name="長方形 112"
                    width="6.058"
                    height="6.057"
                    transform="translate(102.978 84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_113"
                    data-name="長方形 113"
                    width="6.058"
                    height="6.057"
                    transform="translate(121.15 84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_114"
                    data-name="長方形 114"
                    width="6.057"
                    height="6.057"
                    transform="translate(145.38 84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_115"
                    data-name="長方形 115"
                    width="6.057"
                    height="6.057"
                    transform="translate(169.61 84.805)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_116"
                    data-name="長方形 116"
                    width="12.115"
                    height="6.058"
                    transform="translate(12.115 90.863)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_117"
                    data-name="長方形 117"
                    width="6.058"
                    height="6.058"
                    transform="translate(30.288 90.863)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_118"
                    data-name="長方形 118"
                    width="24.23"
                    height="6.058"
                    transform="translate(48.46 90.863)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_119"
                    data-name="長方形 119"
                    width="6.058"
                    height="6.058"
                    transform="translate(96.92 90.863)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_120"
                    data-name="長方形 120"
                    width="6.058"
                    height="6.058"
                    transform="translate(121.15 90.863)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_121"
                    data-name="長方形 121"
                    width="12.115"
                    height="6.058"
                    transform="translate(133.265 90.863)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_122"
                    data-name="長方形 122"
                    width="6.058"
                    height="6.058"
                    transform="translate(151.438 90.863)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_123"
                    data-name="長方形 123"
                    width="12.115"
                    height="6.058"
                    transform="translate(163.553 90.863)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_124"
                    data-name="長方形 124"
                    width="36.345"
                    height="6.057"
                    transform="translate(18.173 96.92)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_125"
                    data-name="長方形 125"
                    width="6.058"
                    height="6.057"
                    transform="translate(60.575 96.92)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_126"
                    data-name="長方形 126"
                    width="18.173"
                    height="6.057"
                    transform="translate(72.69 96.92)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_127"
                    data-name="長方形 127"
                    width="6.058"
                    height="6.057"
                    transform="translate(96.92 96.92)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_128"
                    data-name="長方形 128"
                    width="12.115"
                    height="6.057"
                    transform="translate(115.093 96.92)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_129"
                    data-name="長方形 129"
                    width="12.115"
                    height="6.058"
                    transform="translate(0 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_130"
                    data-name="長方形 130"
                    width="6.058"
                    height="6.058"
                    transform="translate(24.23 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_131"
                    data-name="長方形 131"
                    width="6.058"
                    height="6.058"
                    transform="translate(42.403 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_132"
                    data-name="長方形 132"
                    width="12.115"
                    height="6.058"
                    transform="translate(54.518 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_133"
                    data-name="長方形 133"
                    width="6.058"
                    height="6.058"
                    transform="translate(72.69 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_134"
                    data-name="長方形 134"
                    width="6.058"
                    height="6.058"
                    transform="translate(84.805 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_135"
                    data-name="長方形 135"
                    width="6.058"
                    height="6.058"
                    transform="translate(96.92 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_136"
                    data-name="長方形 136"
                    width="6.058"
                    height="6.058"
                    transform="translate(115.093 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_137"
                    data-name="長方形 137"
                    width="18.173"
                    height="6.058"
                    transform="translate(133.265 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_138"
                    data-name="長方形 138"
                    width="6.058"
                    height="6.058"
                    transform="translate(157.495 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_139"
                    data-name="長方形 139"
                    width="6.057"
                    height="6.058"
                    transform="translate(169.61 102.978)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_140"
                    data-name="長方形 140"
                    width="6.058"
                    height="6.058"
                    transform="translate(18.173 109.035)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_141"
                    data-name="長方形 141"
                    width="12.115"
                    height="6.058"
                    transform="translate(30.288 109.035)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_142"
                    data-name="長方形 142"
                    width="12.115"
                    height="6.058"
                    transform="translate(54.518 109.035)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_143"
                    data-name="長方形 143"
                    width="12.115"
                    height="6.058"
                    transform="translate(72.69 109.035)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_144"
                    data-name="長方形 144"
                    width="6.058"
                    height="6.058"
                    transform="translate(90.863 109.035)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_145"
                    data-name="長方形 145"
                    width="12.115"
                    height="6.058"
                    transform="translate(109.035 109.035)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_146"
                    data-name="長方形 146"
                    width="12.115"
                    height="6.058"
                    transform="translate(145.38 109.035)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_147"
                    data-name="長方形 147"
                    width="6.057"
                    height="6.058"
                    transform="translate(169.61 109.035)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_148"
                    data-name="長方形 148"
                    width="6.058"
                    height="6.058"
                    transform="translate(12.115 115.093)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_149"
                    data-name="長方形 149"
                    width="6.058"
                    height="6.058"
                    transform="translate(24.23 115.093)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_150"
                    data-name="長方形 150"
                    width="6.058"
                    height="6.058"
                    transform="translate(42.403 115.093)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_151"
                    data-name="長方形 151"
                    width="6.058"
                    height="6.058"
                    transform="translate(54.518 115.093)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_152"
                    data-name="長方形 152"
                    width="6.058"
                    height="6.058"
                    transform="translate(90.863 115.093)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_153"
                    data-name="長方形 153"
                    width="18.173"
                    height="6.058"
                    transform="translate(109.035 115.093)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_154"
                    data-name="長方形 154"
                    width="6.058"
                    height="6.058"
                    transform="translate(139.323 115.093)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_155"
                    data-name="長方形 155"
                    width="12.115"
                    height="6.058"
                    transform="translate(163.553 115.093)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_156"
                    data-name="長方形 156"
                    width="12.115"
                    height="6.058"
                    transform="translate(0 121.15)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_157"
                    data-name="長方形 157"
                    width="18.173"
                    height="6.058"
                    transform="translate(30.288 121.15)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_158"
                    data-name="長方形 158"
                    width="24.23"
                    height="6.058"
                    transform="translate(54.518 121.15)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_159"
                    data-name="長方形 159"
                    width="6.058"
                    height="6.058"
                    transform="translate(96.92 121.15)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_160"
                    data-name="長方形 160"
                    width="6.058"
                    height="6.058"
                    transform="translate(109.035 121.15)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_161"
                    data-name="長方形 161"
                    width="36.345"
                    height="6.058"
                    transform="translate(121.15 121.15)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_162"
                    data-name="長方形 162"
                    width="6.058"
                    height="6.058"
                    transform="translate(163.553 121.15)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_163"
                    data-name="長方形 163"
                    width="12.115"
                    height="6.058"
                    transform="translate(48.46 127.208)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_164"
                    data-name="長方形 164"
                    width="12.115"
                    height="6.058"
                    transform="translate(72.69 127.208)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_165"
                    data-name="長方形 165"
                    width="36.345"
                    height="6.058"
                    transform="translate(90.863 127.208)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_166"
                    data-name="長方形 166"
                    width="6.057"
                    height="6.058"
                    transform="translate(145.38 127.208)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_167"
                    data-name="長方形 167"
                    width="18.173"
                    height="6.058"
                    transform="translate(157.495 127.208)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_168"
                    data-name="長方形 168"
                    width="42.403"
                    height="6.058"
                    transform="translate(0 133.265)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_169"
                    data-name="長方形 169"
                    width="6.058"
                    height="6.058"
                    transform="translate(48.46 133.265)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_170"
                    data-name="長方形 170"
                    width="6.058"
                    height="6.058"
                    transform="translate(66.633 133.265)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_171"
                    data-name="長方形 171"
                    width="24.23"
                    height="6.058"
                    transform="translate(78.748 133.265)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_172"
                    data-name="長方形 172"
                    width="12.115"
                    height="6.058"
                    transform="translate(115.093 133.265)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_173"
                    data-name="長方形 173"
                    width="6.058"
                    height="6.058"
                    transform="translate(133.265 133.265)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_174"
                    data-name="長方形 174"
                    width="6.057"
                    height="6.058"
                    transform="translate(145.38 133.265)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_175"
                    data-name="長方形 175"
                    width="6.057"
                    height="6.058"
                    transform="translate(169.61 133.265)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_176"
                    data-name="長方形 176"
                    width="6.058"
                    height="6.058"
                    transform="translate(0 139.323)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_177"
                    data-name="長方形 177"
                    width="6.058"
                    height="6.058"
                    transform="translate(36.345 139.323)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_178"
                    data-name="長方形 178"
                    width="12.115"
                    height="6.058"
                    transform="translate(48.46 139.323)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_179"
                    data-name="長方形 179"
                    width="6.058"
                    height="6.058"
                    transform="translate(78.748 139.323)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_180"
                    data-name="長方形 180"
                    width="6.058"
                    height="6.058"
                    transform="translate(90.863 139.323)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_181"
                    data-name="長方形 181"
                    width="6.058"
                    height="6.058"
                    transform="translate(102.978 139.323)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_182"
                    data-name="長方形 182"
                    width="12.115"
                    height="6.058"
                    transform="translate(115.093 139.323)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_183"
                    data-name="長方形 183"
                    width="12.115"
                    height="6.058"
                    transform="translate(145.38 139.323)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_184"
                    data-name="長方形 184"
                    width="6.057"
                    height="6.058"
                    transform="translate(169.61 139.323)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_185"
                    data-name="長方形 185"
                    width="6.058"
                    height="6.058"
                    transform="translate(0 145.38)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_186"
                    data-name="長方形 186"
                    width="18.173"
                    height="6.058"
                    transform="translate(12.115 145.38)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_187"
                    data-name="長方形 187"
                    width="6.058"
                    height="6.058"
                    transform="translate(36.345 145.38)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_188"
                    data-name="長方形 188"
                    width="6.058"
                    height="6.058"
                    transform="translate(48.46 145.38)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_189"
                    data-name="長方形 189"
                    width="78.748"
                    height="6.058"
                    transform="translate(72.69 145.38)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_190"
                    data-name="長方形 190"
                    width="12.115"
                    height="6.058"
                    transform="translate(163.553 145.38)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_191"
                    data-name="長方形 191"
                    width="6.058"
                    height="6.058"
                    transform="translate(0 151.438)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_192"
                    data-name="長方形 192"
                    width="18.173"
                    height="6.058"
                    transform="translate(12.115 151.438)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_193"
                    data-name="長方形 193"
                    width="6.058"
                    height="6.058"
                    transform="translate(36.345 151.438)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_194"
                    data-name="長方形 194"
                    width="12.115"
                    height="6.058"
                    transform="translate(66.633 151.438)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_195"
                    data-name="長方形 195"
                    width="6.058"
                    height="6.058"
                    transform="translate(90.863 151.438)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_196"
                    data-name="長方形 196"
                    width="6.058"
                    height="6.058"
                    transform="translate(109.035 151.438)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_197"
                    data-name="長方形 197"
                    width="6.058"
                    height="6.058"
                    transform="translate(139.323 151.438)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_198"
                    data-name="長方形 198"
                    width="6.058"
                    height="6.058"
                    transform="translate(163.553 151.438)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_199"
                    data-name="長方形 199"
                    width="6.058"
                    height="6.058"
                    transform="translate(0 157.495)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_200"
                    data-name="長方形 200"
                    width="18.173"
                    height="6.058"
                    transform="translate(12.115 157.495)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_201"
                    data-name="長方形 201"
                    width="6.058"
                    height="6.058"
                    transform="translate(36.345 157.495)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_202"
                    data-name="長方形 202"
                    width="6.058"
                    height="6.058"
                    transform="translate(54.518 157.495)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_203"
                    data-name="長方形 203"
                    width="6.058"
                    height="6.058"
                    transform="translate(66.633 157.495)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_204"
                    data-name="長方形 204"
                    width="6.058"
                    height="6.058"
                    transform="translate(84.805 157.495)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_205"
                    data-name="長方形 205"
                    width="18.173"
                    height="6.058"
                    transform="translate(115.093 157.495)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_206"
                    data-name="長方形 206"
                    width="18.173"
                    height="6.058"
                    transform="translate(157.495 157.495)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_207"
                    data-name="長方形 207"
                    width="6.058"
                    height="6.058"
                    transform="translate(0 163.553)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_208"
                    data-name="長方形 208"
                    width="6.058"
                    height="6.058"
                    transform="translate(36.345 163.553)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_209"
                    data-name="長方形 209"
                    width="24.23"
                    height="6.058"
                    transform="translate(54.518 163.553)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_210"
                    data-name="長方形 210"
                    width="12.115"
                    height="6.058"
                    transform="translate(84.805 163.553)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_211"
                    data-name="長方形 211"
                    width="6.058"
                    height="6.058"
                    transform="translate(109.035 163.553)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_212"
                    data-name="長方形 212"
                    width="6.058"
                    height="6.058"
                    transform="translate(139.323 163.553)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_213"
                    data-name="長方形 213"
                    width="6.058"
                    height="6.058"
                    transform="translate(151.438 163.553)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_214"
                    data-name="長方形 214"
                    width="12.115"
                    height="6.058"
                    transform="translate(163.553 163.553)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_215"
                    data-name="長方形 215"
                    width="42.403"
                    height="6.058"
                    transform="translate(0 169.61)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_216"
                    data-name="長方形 216"
                    width="24.23"
                    height="6.058"
                    transform="translate(54.518 169.61)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_217"
                    data-name="長方形 217"
                    width="30.288"
                    height="6.058"
                    transform="translate(109.035 169.61)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_218"
                    data-name="長方形 218"
                    width="12.115"
                    height="6.058"
                    transform="translate(145.38 169.61)"
                    fill="#fff"
                  />
                  <rect
                    id="長方形_219"
                    data-name="長方形 219"
                    width="6.058"
                    height="6.058"
                    transform="translate(163.553 169.61)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Icon;
