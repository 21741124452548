import React from "react";

const Icon = (props) => {
  const { color, width, height } = props;

  return (
    <svg
      id="グループ_35"
      data-name="グループ 35"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width || "23.013"}
      height={height || "30.008"}
      viewBox="0 0 23.013 30.008"
    >
      <g id="グループ_34" data-name="グループ 34">
        <path
          id="パス_17"
          data-name="パス 17"
          d="M19.919,5.412H17.7a3.083,3.083,0,0,0-2.933-2.156,3.256,3.256,0,0,0-6.513,0A3.083,3.083,0,0,0,5.317,5.412H3.094A3.094,3.094,0,0,0,0,8.506V26.914a3.094,3.094,0,0,0,3.094,3.094H19.919a3.094,3.094,0,0,0,3.094-3.094V8.506a3.094,3.094,0,0,0-3.094-3.094m1.094,21.5a1.1,1.1,0,0,1-1.094,1.094H3.094A1.1,1.1,0,0,1,2,26.914V8.506A1.1,1.1,0,0,1,3.094,7.412H5.157v.083A3.093,3.093,0,0,0,8.25,10.589h6.513a3.093,3.093,0,0,0,3.093-3.094V7.412h2.063a1.1,1.1,0,0,1,1.094,1.094Z"
          fill={color || "#fff"}
        />
        <path
          id="パス_18"
          data-name="パス 18"
          d="M17.346,13.694H5.666a1,1,0,1,0,0,2h11.68a1,1,0,0,0,0-2"
          fill={color || "#fff"}
        />
        <path
          id="パス_19"
          data-name="パス 19"
          d="M17.346,17.9H5.666a1,1,0,0,0,0,2h11.68a1,1,0,0,0,0-2"
          fill={color || "#fff"}
        />
        <path
          id="パス_20"
          data-name="パス 20"
          d="M17.346,22.111H5.666a1,1,0,0,0,0,2h11.68a1,1,0,0,0,0-2"
          fill={color || "#fff"}
        />
      </g>
    </svg>
  );
};

export default Icon;
