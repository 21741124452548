import React, { Component } from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <>
    <footer className="main">
      &copy; 2021 <strong>みまもりファーマシー</strong> Powered by{" "}
      <Link to="http://www.e-windy.co.jp/" target="_blank">
        Windy
      </Link>
    </footer>
  </>
);

export default Footer;
