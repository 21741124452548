import React from "react";

const Icon = (props) => {
  return (
    <svg
      id="グループ_57"
      data-name="グループ 57"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width || "24"}
      height={props.height || "24"}
      viewBox="0 0 24 24"
    >
      <g id="グループ_56" data-name="グループ 56" clipPath="url(#clipPath)">
        <path
          id="パス_73"
          data-name="パス 73"
          d="M23,7.77V0H1V7.67L0,8.145V21a3,3,0,0,0,3,3H21c1.374,0,3-1.084,3-2L23.968,8.227Zm-1.032,2.578.025,9.185-5.129-4.975ZM21,2V8.553l-8.156,6.732a1.108,1.108,0,0,1-1.592-.058L3,8.486V2ZM2,19.706V10.251L7.217,14.51ZM3,22a1,1,0,0,1-.39-.078l6.164-6.141,1.146.935a3.076,3.076,0,0,0,2.161.874,3.011,3.011,0,0,0,2.1-.819l1.131-.933,6.226,6.041A1.407,1.407,0,0,1,21,22Z"
          fill={props.color || "#009dad"}
        />
        <rect
          id="長方形_71"
          data-name="長方形 71"
          width="12"
          height="2"
          transform="translate(6 4)"
          fill={props.color || "#009dad"}
        />
        <rect
          id="長方形_72"
          data-name="長方形 72"
          width="9"
          height="2"
          transform="translate(6 7)"
          fill={props.color || "#009dad"}
        />
      </g>
    </svg>
  );
};

export default Icon;
