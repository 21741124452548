import React from "react";

const Icon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width || "24"}
      height={props.height || "24"}
      className={props.className || ""}
      viewBox="0 0 24 24.228"
    >
      <g id="グループ_51" data-name="グループ 51" transform="translate(0 0)">
        <g id="グループ_50" data-name="グループ 50" clipPath="url(#clipPath)">
          <path
            id="パス_63"
            data-name="パス 63"
            d="M21,0H3A3,3,0,0,0,0,3V16a3,3,0,0,0,3,3H4v5.228L9.88,19H21a3,3,0,0,0,3-3V3a3,3,0,0,0-3-3m1,16a1,1,0,0,1-1,1H9.12L6,19.773V17H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"
            fill={props.color || "#009dad"}
          />
          <path
            id="パス_64"
            data-name="パス 64"
            d="M6.958,8a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
            fill={props.color || "#009dad"}
          />
          <path
            id="パス_65"
            data-name="パス 65"
            d="M11.958,8a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
            fill={props.color || "#009dad"}
          />
          <path
            id="パス_66"
            data-name="パス 66"
            d="M16.958,8a1,1,0,1,0,1,1,1,1,0,0,0-1-1"
            fill={props.color || "#009dad"}
          />
        </g>
      </g>
    </svg>
  );
};

export default Icon;
