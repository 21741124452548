import React from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../App";
import { LogoAlphaFull } from "../extra/svg/";

const Sidebar = () => (
  <>
    <UserContext.Consumer>
      {({ context }) => (
        <div className="sidebar-menu">
          <div className="sidebar-menu-inner">
            <header className="logo-env">
              <div className="logo">
                <Link to="/">
                  <LogoAlphaFull
                    width={"151px"}
                    height={"44px"}
                    color={"#FFF"}
                  />
                </Link>
              </div>
              <div className="sidebar-collapse">
                <a href="#" className="sidebar-collapse-icon">
                  <i className="entypo-menu"></i>
                </a>
              </div>
              <div className="sidebar-mobile-menu visible-xs">
                <a href="#" className="with-animation">
                  <i className="entypo-menu"></i>
                </a>
              </div>
            </header>
            <div className="sidebar-user-info">
              {context.group == "Pharmacy" ? (
                <div>
                  <a href="#" className="user-link">
                    <img
                      src="/assets/images/thumb-1@2x.png"
                      width="55"
                      alt=""
                      className="img-circle"
                    />
                    <span>
                      {context.data.pharmacyGroup.name == "なし"
                        ? ""
                        : context.data.pharmacyGroup.name}
                    </span>
                    <strong>{context.data.name}</strong>
                  </a>
                </div>
              ) : null}
            </div>
            <ul id="main-menu" className="main-menu">
              <li>
                <Link to="/">
                  <i className="entypo-monitor"></i>
                  <span className="title">ダッシュボード</span>
                </Link>
              </li>
              <li className="has-sub">
                <a>
                  <i className="entypo-user"></i>
                  <span className="title">患者</span>
                </a>
                <ul>
                  <li>
                    <Link to="/Members">
                      <span className="title">一覧・登録</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has-sub">
                <a href="">
                  <i className="entypo-heart"></i>
                  <span className="title">患者フォロー</span>
                </a>
                <ul>
                  <li>
                    <Link to="/SendQuestionnaire">
                      <span className="title">アンケート一覧</span>
                    </Link>
                  </li>
                  {context.group == "Pharmacy" ? (
                    <>
                      <li>
                        <Link to={"/questionSelectSend/" + context.data.id}>
                          <span className="title">
                            アンケート一斉送信（らく歴連動）
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/questionAllSend/" + context.data.id}>
                          <span className="title">
                            アンケート一斉送信（全メンバー）
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/questionTemplate/" + context.data.id}>
                          <span className="title">
                            アンケートテンプレート設定
                          </span>
                        </Link>
                      </li>
                    </>
                  ) : null}
                </ul>
              </li>
              {context.group == "Pharmacy" ? null : (
                <li className="has-sub">
                  <a href="#">
                    <i className="entypo-home"></i>
                    <span className="title">薬局</span>
                  </a>
                  <ul>
                    <li>
                      <Link to="/pharmacyGroups">
                        <span className="title">薬局グループ</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/pharmacies">
                        <span className="title">薬局</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
              {context.group == "Pharmacy" ? null : (
                <li className="has-sub">
                  <a href="#">
                    <i className="entypo-home"></i>
                    <span className="title">各種マスタ設定</span>
                  </a>
                  <ul>
                    <li>
                      <Link to="/questionGroups">
                        <span className="title">
                          アンケートテンプレートカテゴリ
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
              <li className="has-sub">
                <a href="#">
                  <i className="entypo-home"></i>
                  <span className="title">お知らせ</span>
                </a>
                <ul>
                  <li>
                    <Link to="/sendInformations">
                      <span className="title">お知らせ送信</span>
                    </Link>
                  </li>
                  {context.group == "Pharmacy" ? (
                    <li>
                      <Link to="/informations">
                        <span className="title">運営からのお知らせ</span>
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </li>
              {context.group == "Pharmacy" ? (
                <li className="has-sub">
                  <a href="#">
                    <i className="entypo-home"></i>
                    <span className="title">設定</span>
                  </a>
                  <ul>
                    <li>
                      <Link to={"/pharmacy/" + context.data.id}>
                        <span className="title">薬局詳細</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/QR/" + context.data.id}>
                        <span className="title">薬局登録用QRコード</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  </>
);

export default Sidebar;
