import React, { useState } from "react";
import { Auth } from "aws-amplify";

import Modal from "react-modal";
import QRCodeReader from "../common/QRCodeReader";

import { LogoAlphaImgHorizontal, QR } from "../extra/svg";

import "../assets/css/userLogin.scss";

const UserLogin = (props) => {
  const { signInCallback, cookies, cooliesLoginCallback } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const qrReaderCallback = async (v) => {
    if (v) {
      const userData = v.split("&");
      if (userData.length > 2) {
        const username = userData[0];
        const password = userData[1];
        setModalIsOpen(false);
        signIn(username, password);
      }
    }
  };

  const signIn = async (username, password) => {
    try {
      await Auth.signIn(username, password);
      signInCallback(username, password);
    } catch (error) {
      console.log("error signing in", error);
    }
  };

  return (
    <div className="user-login-wrapper">
      <div className="login">
        <LogoAlphaImgHorizontal
          className={"logo"}
          width={"50%"}
          height={"auto"}
          color={"#FFF"}
        />
        <QR className={"qr"} width={"25%"} height={"auto"} color={"#FFF"} />
        <button className="login-button" onClick={() => setModalIsOpen(true)}>
          QRコードを読み取ってログイン
        </button>
        {cookies.id && cookies.password ? (
          <p
            className="cookie-login-button"
            onClick={() => cooliesLoginCallback()}
          >
            前回のログイン情報でログイン
          </p>
        ) : null}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        overlayClassName={{
          base: "overlay-base",
          afterOpen: "overlay-after",
          beforeClose: "overlay-before",
        }}
        className={{
          base: "content-base",
          afterOpen: "content-camera-after",
          beforeClose: "content-before",
        }}
        closeTimeoutMS={500}
        style={{ "content-base": { background: "none" } }}
      >
        <QRCodeReader qrReaderCallback={(v) => qrReaderCallback(v)} />
      </Modal>
    </div>
  );
};

export default UserLogin;
