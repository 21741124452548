import React, { useEffect, Suspense, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import routes from "./config/routes";
import userRoutes from "./config/userRoutes";
import "./assets/css/custom.scss";
import { useCookies } from "react-cookie";
import Modal from "react-modal";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

// AWS Amplify
import Amplify, { Auth, API, graphqlOperation } from "aws-amplify";
import config from "./aws-exports";

// Common
import Login from "./pages/Login";
import UserLogin from "./pages/UserLogin";
import Layout from "./common/Layout";
import UserLayout from "./common/UserLayout";
import {
  queryAdminInformationList,
  getPharmacy,
  getUser,
} from "./graphql/queries";
import { updateUser } from "./graphql/mutations";

export const UserContext = React.createContext({});

Amplify.configure(config);

const firebaseConfig = {
  apiKey: "AIzaSyCVIZ5od_bHWkZg-g9453mZJ9V205USVJM",
  authDomain: "mimamoripharmacy.firebaseapp.com",
  projectId: "mimamoripharmacy",
  storageBucket: "mimamoripharmacy.appspot.com",
  messagingSenderId: "694073338455",
  appId: "1:694073338455:web:a4a40800c6630a96621112",
  measurementId: "G-D5Y4NEPWVP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

const vapidKey =
  "BO0kjQzssbk7tQ8ZFi38HJG8oU73Kur-qET0UssCXeh8dfvOJwKrzq7nKIDu_plbcVE-mWDhqizMdfnd4lQu4PM";

var registration;

const App = (props) => {
  const [authState, setAuthState] = useState(props.authState || null);
  const [userGroup, setUserGroup] = useState(props.userGroup || null);
  const [context, setContext] = useState({});
  const [deviceToken, setDeviceToken] = useState(null);
  const [pnModalIsOpen, setPNModalIsOpen] = useState(false);
  const [notificationCounts, setNotificationCounts] = useState({});

  const search = window.location.search
    .slice(1)
    .split("&")
    .map((str) => {
      return { [str.split("=")[0]]: str.split("=")[1] };
    })
    .reduce((obj1, obj2) => {
      return Object.assign(obj1, obj2);
    });

  const [cookies, setCookie] = useCookies(["id", "password", "notifications"]);

  useEffect(() => {
    checkLogin();

    if ("serviceWorker" in navigator) {
      console.log("serviceWorker is in navigator.");
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((reg) => {
          registration = reg;
          console.log("Service Worker registered with scope:", reg.scope);
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }

    // LocalStrageに保存されている通知情報を取得
    const storedCounts = localStorage.getItem("notificationCounts");
    if (storedCounts) {
      console.log("storedCounts", storedCounts);
      setNotificationCounts(JSON.parse(storedCounts));
    }
  }, []);

  // 状態が更新されたらローカルストレージに保存する
  useEffect(() => {
    localStorage.setItem(
      "notificationCounts",
      JSON.stringify(notificationCounts)
    );
  }, [notificationCounts]);

  useEffect(() => {
    if (deviceToken && userGroup === "User" && context) {
      updateUserFCMToken(deviceToken);
    }
    if (userGroup === "User" && context) {
      if (Notification.permission !== "granted") {
        setPNModalIsOpen(true);
      }
    }
  }, [deviceToken, userGroup, context]);

  const requestPermission = () => {
    setPNModalIsOpen(false);
    isSupported().then((isSupport) => {
      if (!isSupport) {
        console.log("Messaging is not support.");
        return;
      }

      console.log("Requesting permission...");
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          getTokenRequest();
        }
      });
    });
  };

  const getTokenRequest = () => {
    // Add the public key generated from the console here.
    getToken(messaging, {
      vapidKey: vapidKey,
      serviceWorkerRegistration: registration,
    })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...

          setDeviceToken(currentToken);
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  };

  const updateUserFCMToken = async (token) => {
    const user = await Auth.currentAuthenticatedUser();
    let userUpdateInput = {
      id: user.attributes.sub,
      fcmToken: token,
    };
    try {
      await API.graphql(
        graphqlOperation(updateUser, { input: userUpdateInput })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const checkLogin = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userGroup =
        user.signInUserSession.accessToken.payload["cognito:groups"][0];
      context["group"] = userGroup;

      if (userGroup === "Admin") {
        // 管理者の場合はユーザー情報なし
        context["data"] = {};
      } else if (userGroup === "Pharmacy") {
        // 薬局の場合
        const getParams = {
          id: user.attributes.sub,
        };
        const pharmacy = await API.graphql(
          graphqlOperation(getPharmacy, getParams)
        );
        context["data"] = pharmacy.data.getPharmacy;

        const getInput = {
          isFromAdmin: 1,
        };
        const result = await API.graphql(
          graphqlOperation(queryAdminInformationList, getInput)
        );
        const items = result.data.queryAdminInformationList.items;
        const sortedItems = items.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          } else {
            return -1;
          }
        });
        context["informations"] = sortedItems;
      } else if (userGroup === "User") {
        // 患者の場合
        const getParams = {
          id: user.attributes.sub,
        };

        await API.graphql(graphqlOperation(getUser, getParams)).then((user) => {
          context["data"] = user.data.getUser;
          setContext(context);
        });
      }

      setContext(context);
      setUserGroup(userGroup);
      setAuthState("signedIn");
    } catch (error) {
      setAuthState("signedOut");
    }
  };

  const cookieLogin = async () => {
    const id = cookies.id;
    const password = cookies.password;
    if (id && password) {
      try {
        await Auth.signIn(id, password);
        signInCallback(id, password);
      } catch (error) {
        console.log("error signing in", error);
      }
    } else {
      setAuthState("signedOut");
    }
  };

  const signInCallback = async (id, password) => {
    if (id && password) {
      // Cookieにログイン情報保存
      const cookieExpiration = "2035-12-20 23:59:59";
      const cookieDate = new Date(cookieExpiration);
      setCookie("id", id, { expires: cookieDate, path: "/" });
      setCookie("password", password, { expires: cookieDate, path: "/" });
      checkLogin();
    }
  };

  return (
    <>
      {authState === "signedIn" ? (
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <Switch>
              <UserContext.Provider
                value={{
                  context,
                  setContext,
                  setNotificationCounts,
                  notificationCounts,
                }}
              >
                {userGroup === "User" ? (
                  <UserLayout>
                    {userRoutes.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                      />
                    ))}
                    <Modal
                      isOpen={pnModalIsOpen}
                      onRequestClose={() => setPNModalIsOpen(false)}
                      overlayClassName={{
                        base: "overlay-base",
                        afterOpen: "overlay-after",
                        beforeClose: "overlay-before",
                      }}
                      className={{
                        base: "content-base",
                        afterOpen: "content-notification-after",
                        beforeClose: "content-before",
                      }}
                      closeTimeoutMS={500}
                      style={{ "content-base": { background: "none" } }}
                    >
                      <div className="title">通知の許可</div>
                      <p className="description">
                        通知設定を行います。薬局からのお知らせや、お薬のお知らせを受け取ることができますので、許可に設定することをお勧めします。
                      </p>
                      <button
                        className="button"
                        onClick={() => requestPermission()}
                      >
                        通知設定
                      </button>
                    </Modal>
                  </UserLayout>
                ) : (
                  <Layout>
                    {routes.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                      />
                    ))}
                  </Layout>
                )}
              </UserContext.Provider>
            </Switch>
          </BrowserRouter>
        </Suspense>
      ) : authState === "signedOut" ? (
        <>
          {search["ua"] === "m" ? (
            <UserLogin
              signInCallback={(data, username, password) =>
                signInCallback(data, username, password)
              }
              cookies={cookies}
              cooliesLoginCallback={() => cookieLogin()}
            />
          ) : (
            <Login
              signInCallback={(data, username, password) =>
                signInCallback(data, username, password)
              }
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default App;
