/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      loginId
      password
      name
      furigana
      sex
      birthday
      points
      familyIDs
      tags
      fcmToken
      createdAt
      updatedAt
      pharmacies {
        items {
          id
          windyID
          isMember
          userID
          pharmacyID
          isMyFavorite
          createdAt
          updatedAt
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userID
          pharmacyID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserFamily = /* GraphQL */ `
  query GetUserFamily($id: ID!) {
    getUserFamily(id: $id) {
      id
      userID
      familyUserID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      family {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const listUserFamilys = /* GraphQL */ `
  query ListUserFamilys(
    $filter: ModelUserFamilyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFamilys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        familyUserID
        createdAt
        updatedAt
        user {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
        family {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserPharmacies = /* GraphQL */ `
  query GetUserPharmacies($id: ID!) {
    getUserPharmacies(id: $id) {
      id
      windyID
      isMember
      userID
      pharmacyID
      isMyFavorite
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const listUserPharmaciess = /* GraphQL */ `
  query ListUserPharmaciess(
    $filter: ModelUserPharmaciesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPharmaciess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        windyID
        isMember
        userID
        pharmacyID
        isMyFavorite
        createdAt
        updatedAt
        user {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
        pharmacy {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPharmacy = /* GraphQL */ `
  query GetPharmacy($id: ID!) {
    getPharmacy(id: $id) {
      id
      pharmacyGroupID
      name
      loginId
      loginPassword
      email
      address
      phonenumber
      isPushSound
      helpStartTime
      helpEndTime
      startTimes
      endTimes
      holidays
      pictureURLs
      readInformationsIds
      isGenericDefault
      isTechouDefault
      memo
      createdAt
      updatedAt
      users {
        items {
          id
          windyID
          isMember
          userID
          pharmacyID
          isMyFavorite
          createdAt
          updatedAt
        }
        nextToken
      }
      pharmacyGroup {
        id
        name
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
      }
      chatRooms {
        items {
          id
          userID
          pharmacyID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPharmacys = /* GraphQL */ `
  query ListPharmacys(
    $filter: ModelPharmacyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPharmacys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPharmacyGroup = /* GraphQL */ `
  query GetPharmacyGroup($id: ID!) {
    getPharmacyGroup(id: $id) {
      id
      name
      createdAt
      updatedAt
      pharmacies {
        items {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPharmacyGroups = /* GraphQL */ `
  query ListPharmacyGroups(
    $filter: ModelPharmacyGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPharmacyGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      userID
      pharmacyID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
      messages {
        items {
          id
          messageID
          isMember
          isRead
          isQuestionnaire
          stateFlg
          questionnaireJSON
          sendTime
          isAnswer
          imageURL
          content
          pharmacyID
          pharmacyIDForSubscription
          recieveID
          shohouData
          isShohouProcessed
          familyUserID
          updatedAt
          createdAt
          answerAt
        }
        nextToken
      }
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        pharmacyID
        createdAt
        updatedAt
        user {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
        pharmacy {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      messageID
      isMember
      isRead
      isQuestionnaire
      stateFlg
      questionnaireJSON
      sendTime
      isAnswer
      imageURL
      content
      pharmacyID
      pharmacyIDForSubscription
      recieveID
      shohouData
      isShohouProcessed
      familyUserID
      updatedAt
      createdAt
      answerAt
      family {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageID
        isMember
        isRead
        isQuestionnaire
        stateFlg
        questionnaireJSON
        sendTime
        isAnswer
        imageURL
        content
        pharmacyID
        pharmacyIDForSubscription
        recieveID
        shohouData
        isShohouProcessed
        familyUserID
        updatedAt
        createdAt
        answerAt
        family {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
        user {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
        pharmacy {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMessageForAndroid = /* GraphQL */ `
  query GetMessageForAndroid($id: ID!) {
    getMessageForAndroid(id: $id) {
      id
      pharmacyID
      userID
      familyUserID
      content
      createdAt
      updatedAt
    }
  }
`;
export const listMessageForAndroids = /* GraphQL */ `
  query ListMessageForAndroids(
    $filter: ModelMessageForAndroidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageForAndroids(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pharmacyID
        userID
        familyUserID
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionGroup = /* GraphQL */ `
  query GetQuestionGroup($id: ID!) {
    getQuestionGroup(id: $id) {
      id
      name
      updatedAt
      createdAt
      questionJSONs {
        items {
          id
          questionGroupID
          name
          questionnaireJSON
          pharmacyID
          userID
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const listQuestionGroups = /* GraphQL */ `
  query ListQuestionGroups(
    $filter: ModelQuestionGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        updatedAt
        createdAt
        questionJSONs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionJSON = /* GraphQL */ `
  query GetQuestionJSON($id: ID!) {
    getQuestionJSON(id: $id) {
      id
      questionGroupID
      name
      questionnaireJSON
      pharmacyID
      userID
      updatedAt
      createdAt
      questionGroup {
        id
        name
        updatedAt
        createdAt
        questionJSONs {
          nextToken
        }
      }
    }
  }
`;
export const listQuestionJSONs = /* GraphQL */ `
  query ListQuestionJSONs(
    $filter: ModelQuestionJSONFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionJSONs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionGroupID
        name
        questionnaireJSON
        pharmacyID
        userID
        updatedAt
        createdAt
        questionGroup {
          id
          name
          updatedAt
          createdAt
        }
      }
      nextToken
    }
  }
`;
export const getQuestionSendSchedule = /* GraphQL */ `
  query GetQuestionSendSchedule($id: ID!) {
    getQuestionSendSchedule(id: $id) {
      id
      messageID
      isMember
      isRead
      isQuestionnaire
      stateFlg
      questionnaireJSON
      sendTime
      isAnswer
      imageURL
      content
      pharmacyID
      familyUserID
      recieveID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const listQuestionSendSchedules = /* GraphQL */ `
  query ListQuestionSendSchedules(
    $filter: ModelQuestionSendScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionSendSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageID
        isMember
        isRead
        isQuestionnaire
        stateFlg
        questionnaireJSON
        sendTime
        isAnswer
        imageURL
        content
        pharmacyID
        familyUserID
        recieveID
        createdAt
        updatedAt
        user {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getQuestionRequestFromRakureki = /* GraphQL */ `
  query GetQuestionRequestFromRakureki($id: ID!) {
    getQuestionRequestFromRakureki(id: $id) {
      id
      windyID
      userID
      pharmacyID
      pharmacistName
      memo
      tags
      scheduleTime
      sendedFlg
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const listQuestionRequestFromRakurekis = /* GraphQL */ `
  query ListQuestionRequestFromRakurekis(
    $filter: ModelQuestionRequestFromRakurekiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionRequestFromRakurekis(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        windyID
        userID
        pharmacyID
        pharmacistName
        memo
        tags
        scheduleTime
        sendedFlg
        createdAt
        updatedAt
        user {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
        pharmacy {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getInformations = /* GraphQL */ `
  query GetInformations($id: ID!) {
    getInformations(id: $id) {
      id
      title
      message
      isFromAdmin
      pharmacyID
      userID
      updatedAt
      createdAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const listInformationss = /* GraphQL */ `
  query ListInformationss(
    $filter: ModelInformationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInformationss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        message
        isFromAdmin
        pharmacyID
        userID
        updatedAt
        createdAt
        user {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
        pharmacy {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryAdminInformationList = /* GraphQL */ `
  query QueryAdminInformationList(
    $isFromAdmin: Int
    $sortDirection: ModelSortDirection
    $filter: ModelInformationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAdminInformationList(
      isFromAdmin: $isFromAdmin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        message
        isFromAdmin
        pharmacyID
        userID
        updatedAt
        createdAt
        user {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
        pharmacy {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryPharmacyInformationList = /* GraphQL */ `
  query QueryPharmacyInformationList(
    $pharmacyID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInformationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryPharmacyInformationList(
      pharmacyID: $pharmacyID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        message
        isFromAdmin
        pharmacyID
        userID
        updatedAt
        createdAt
        user {
          id
          loginId
          password
          name
          furigana
          sex
          birthday
          points
          familyIDs
          tags
          fcmToken
          createdAt
          updatedAt
        }
        pharmacy {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
