import React from "react";

const Icon = (props) => {
  return (
    <svg
      id="グループ_49"
      data-name="グループ 49"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width || "18"}
      height={props.height || "23"}
      className={props.className || ""}
      viewBox="0 0 18 23"
    >
      <rect
        id="長方形_65"
        data-name="長方形 65"
        width="7.999"
        height="2"
        transform="translate(5 21)"
        fill={props.color || "#009dad"}
      />
      <g id="グループ_48" data-name="グループ 48">
        <g id="グループ_47" data-name="グループ 47" clipPath="url(#clipPath)">
          <path
            id="パス_62"
            data-name="パス 62"
            d="M5,17.06V20h8V17.06a9,9,0,1,0-8,0M9,2a7,7,0,0,1,2.624,13.486L11,15.739V18H10V12h1V10H7v2H8v6H7V15.739l-.624-.253A7,7,0,0,1,9,2"
            fill={props.color || "#009dad"}
          />
        </g>
      </g>
    </svg>
  );
};

export default Icon;
