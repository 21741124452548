/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://rpszx2fus1.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "checkExistMimamoriUserApi",
            "endpoint": "https://gb3vy6nfh1.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminCreateQuestionFromRakurekiApi",
            "endpoint": "https://3a1t63kcch.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminCreateUsersApi",
            "endpoint": "https://y89p9gdms8.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminGetNotHaveMIDUserApi",
            "endpoint": "https://04adhyorob.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminGetUserFromRakurekiIDApi",
            "endpoint": "https://75shvun2a8.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminListUsersApi",
            "endpoint": "https://omduopni6i.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminQuestionApi",
            "endpoint": "https://qndywnkrk1.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminShohousenListApi",
            "endpoint": "https://mc890veij4.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminShohousenSetFlgApi",
            "endpoint": "https://u3bqxdl8kb.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminPushNotificationApi",
            "endpoint": "https://idiv8wa18i.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "windyAdminMultiPushNotificationApi",
            "endpoint": "https://7epqupb2xg.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://leihwjrolbcqjldojk67eq2b7u.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:47d378aa-a20c-41ca-bc3b-919e1da80bdf",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_ADBGlrcF7",
    "aws_user_pools_web_client_id": "7drmb63qnp6d9306dd7dd6vdn6",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "windyadmin43c46065694a46a6807f9e44e587905b225354-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "cd7ead0f4e50403f818e3ebe6fcfba69",
            "region": "us-west-2"
        }
    },
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
