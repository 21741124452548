import React from "react";

// Layouts
import Chat from "./Chat";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const Layout = (props) => (
  <div className="page-container">
    <Sidebar />
    <div className="main-content">
      <Header />
      {props.children}
      <Footer />
    </div>
    <Chat />
  </div>
);
export default Layout;
