import React from "react";
const Pharmacies = React.lazy(() => import("../pages/Pharmacies"));
const PharmacyDetail = React.lazy(() => import("../pages/Pharmacy/Detail"));
const PharmacyGroups = React.lazy(() => import("../pages/PharmacyGroups"));
const PharmacyGroupDetail = React.lazy(() =>
  import("../pages/PharmacyGroup/Detail")
);
const SendQuestionnaire = React.lazy(() =>
  import("../pages/SendQuestionnaire")
);
const QuestionGroups = React.lazy(() => import("../pages/QuestionGroups"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Members = React.lazy(() => import("../pages/Members"));
const MemberDetail = React.lazy(() => import("../pages/Member/Detail"));
const QuestionTemplate = React.lazy(() => import("../pages/QuestionTemplate"));
const QuestionSendSchedule = React.lazy(() =>
  import("../pages/QuestionSendSchedule")
);
const QuestionSelectSend = React.lazy(() =>
  import("../pages/QuestionSelectSend")
);
const QR = React.lazy(() => import("../pages/Pharmacy/QR"));
const PointQR = React.lazy(() => import("../pages/Pharmacy/PointQR"));
const Informations = React.lazy(() => import("../pages/Informations"));
const SendInformations = React.lazy(() => import("../pages/SendInformations"));
const QuestionAllSend = React.lazy(() => import("../pages/QuestionAllSend"));

const routes = [
  { path: "/", exact: true, name: "Dashboard", component: Dashboard },
  { path: "/members", exact: true, name: "Members", component: Members },
  {
    path: "/member/:id",
    exact: true,
    name: "MemberDetail",
    component: MemberDetail,
  },
  {
    path: "/pharmacies",
    exact: true,
    name: "Pharmacies",
    component: Pharmacies,
  },
  {
    path: "/pharmacy/:id",
    exact: true,
    name: "PharmacyDetail",
    component: PharmacyDetail,
  },
  {
    path: "/pharmacyGroups",
    exact: true,
    name: "PharmacyGroups",
    component: PharmacyGroups,
  },
  {
    path: "/pharmacyGroup/:id",
    exact: true,
    name: "PharmacyGroupDetail",
    component: PharmacyGroupDetail,
  },
  {
    path: "/sendQuestionnaire",
    exact: true,
    name: "SendQuestionnaire",
    component: SendQuestionnaire,
  },
  {
    path: "/questionGroups",
    exact: true,
    name: "QuestionGroups",
    component: QuestionGroups,
  },
  {
    path: "/questionTemplate/:id",
    exact: true,
    name: "QuestionTemplate",
    component: QuestionTemplate,
  },
  {
    path: "/questionSendSchedule/:id",
    exact: true,
    name: "QuestionSendSchedule",
    component: QuestionSendSchedule,
  },
  {
    path: "/questionSelectSend/:id",
    exact: true,
    name: "QuestionSelectSend",
    component: QuestionSelectSend,
  },
  { path: "/QR/:id", exact: true, name: "QR", component: QR },
  { path: "/PointQR/:id", exact: true, name: "PointQR", component: PointQR },
  {
    path: "/informations",
    exact: true,
    name: "Informations",
    component: Informations,
  },
  {
    path: "/sendInformations",
    exact: true,
    name: "SendInformations",
    component: SendInformations,
  },
  {
    path: "/questionAllSend/:id",
    exact: true,
    name: "QuestionAllSend",
    component: QuestionAllSend,
  },
];

export default routes;
