/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      loginId
      password
      name
      furigana
      sex
      birthday
      points
      familyIDs
      tags
      fcmToken
      createdAt
      updatedAt
      pharmacies {
        items {
          id
          windyID
          isMember
          userID
          pharmacyID
          isMyFavorite
          createdAt
          updatedAt
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userID
          pharmacyID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      loginId
      password
      name
      furigana
      sex
      birthday
      points
      familyIDs
      tags
      fcmToken
      createdAt
      updatedAt
      pharmacies {
        items {
          id
          windyID
          isMember
          userID
          pharmacyID
          isMyFavorite
          createdAt
          updatedAt
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userID
          pharmacyID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      loginId
      password
      name
      furigana
      sex
      birthday
      points
      familyIDs
      tags
      fcmToken
      createdAt
      updatedAt
      pharmacies {
        items {
          id
          windyID
          isMember
          userID
          pharmacyID
          isMyFavorite
          createdAt
          updatedAt
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userID
          pharmacyID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUserFamily = /* GraphQL */ `
  mutation CreateUserFamily(
    $input: CreateUserFamilyInput!
    $condition: ModelUserFamilyConditionInput
  ) {
    createUserFamily(input: $input, condition: $condition) {
      id
      userID
      familyUserID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      family {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const updateUserFamily = /* GraphQL */ `
  mutation UpdateUserFamily(
    $input: UpdateUserFamilyInput!
    $condition: ModelUserFamilyConditionInput
  ) {
    updateUserFamily(input: $input, condition: $condition) {
      id
      userID
      familyUserID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      family {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserFamily = /* GraphQL */ `
  mutation DeleteUserFamily(
    $input: DeleteUserFamilyInput!
    $condition: ModelUserFamilyConditionInput
  ) {
    deleteUserFamily(input: $input, condition: $condition) {
      id
      userID
      familyUserID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      family {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const createUserPharmacies = /* GraphQL */ `
  mutation CreateUserPharmacies(
    $input: CreateUserPharmaciesInput!
    $condition: ModelUserPharmaciesConditionInput
  ) {
    createUserPharmacies(input: $input, condition: $condition) {
      id
      windyID
      isMember
      userID
      pharmacyID
      isMyFavorite
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const updateUserPharmacies = /* GraphQL */ `
  mutation UpdateUserPharmacies(
    $input: UpdateUserPharmaciesInput!
    $condition: ModelUserPharmaciesConditionInput
  ) {
    updateUserPharmacies(input: $input, condition: $condition) {
      id
      windyID
      isMember
      userID
      pharmacyID
      isMyFavorite
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserPharmacies = /* GraphQL */ `
  mutation DeleteUserPharmacies(
    $input: DeleteUserPharmaciesInput!
    $condition: ModelUserPharmaciesConditionInput
  ) {
    deleteUserPharmacies(input: $input, condition: $condition) {
      id
      windyID
      isMember
      userID
      pharmacyID
      isMyFavorite
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const createPharmacy = /* GraphQL */ `
  mutation CreatePharmacy(
    $input: CreatePharmacyInput!
    $condition: ModelPharmacyConditionInput
  ) {
    createPharmacy(input: $input, condition: $condition) {
      id
      pharmacyGroupID
      name
      loginId
      loginPassword
      email
      address
      phonenumber
      isPushSound
      helpStartTime
      helpEndTime
      startTimes
      endTimes
      holidays
      pictureURLs
      readInformationsIds
      isGenericDefault
      isTechouDefault
      memo
      createdAt
      updatedAt
      users {
        items {
          id
          windyID
          isMember
          userID
          pharmacyID
          isMyFavorite
          createdAt
          updatedAt
        }
        nextToken
      }
      pharmacyGroup {
        id
        name
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
      }
      chatRooms {
        items {
          id
          userID
          pharmacyID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePharmacy = /* GraphQL */ `
  mutation UpdatePharmacy(
    $input: UpdatePharmacyInput!
    $condition: ModelPharmacyConditionInput
  ) {
    updatePharmacy(input: $input, condition: $condition) {
      id
      pharmacyGroupID
      name
      loginId
      loginPassword
      email
      address
      phonenumber
      isPushSound
      helpStartTime
      helpEndTime
      startTimes
      endTimes
      holidays
      pictureURLs
      readInformationsIds
      isGenericDefault
      isTechouDefault
      memo
      createdAt
      updatedAt
      users {
        items {
          id
          windyID
          isMember
          userID
          pharmacyID
          isMyFavorite
          createdAt
          updatedAt
        }
        nextToken
      }
      pharmacyGroup {
        id
        name
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
      }
      chatRooms {
        items {
          id
          userID
          pharmacyID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePharmacy = /* GraphQL */ `
  mutation DeletePharmacy(
    $input: DeletePharmacyInput!
    $condition: ModelPharmacyConditionInput
  ) {
    deletePharmacy(input: $input, condition: $condition) {
      id
      pharmacyGroupID
      name
      loginId
      loginPassword
      email
      address
      phonenumber
      isPushSound
      helpStartTime
      helpEndTime
      startTimes
      endTimes
      holidays
      pictureURLs
      readInformationsIds
      isGenericDefault
      isTechouDefault
      memo
      createdAt
      updatedAt
      users {
        items {
          id
          windyID
          isMember
          userID
          pharmacyID
          isMyFavorite
          createdAt
          updatedAt
        }
        nextToken
      }
      pharmacyGroup {
        id
        name
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
      }
      chatRooms {
        items {
          id
          userID
          pharmacyID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPharmacyGroup = /* GraphQL */ `
  mutation CreatePharmacyGroup(
    $input: CreatePharmacyGroupInput!
    $condition: ModelPharmacyGroupConditionInput
  ) {
    createPharmacyGroup(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      pharmacies {
        items {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePharmacyGroup = /* GraphQL */ `
  mutation UpdatePharmacyGroup(
    $input: UpdatePharmacyGroupInput!
    $condition: ModelPharmacyGroupConditionInput
  ) {
    updatePharmacyGroup(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      pharmacies {
        items {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePharmacyGroup = /* GraphQL */ `
  mutation DeletePharmacyGroup(
    $input: DeletePharmacyGroupInput!
    $condition: ModelPharmacyGroupConditionInput
  ) {
    deletePharmacyGroup(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      pharmacies {
        items {
          id
          pharmacyGroupID
          name
          loginId
          loginPassword
          email
          address
          phonenumber
          isPushSound
          helpStartTime
          helpEndTime
          startTimes
          endTimes
          holidays
          pictureURLs
          readInformationsIds
          isGenericDefault
          isTechouDefault
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      userID
      pharmacyID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
      messages {
        items {
          id
          messageID
          isMember
          isRead
          isQuestionnaire
          stateFlg
          questionnaireJSON
          sendTime
          isAnswer
          imageURL
          content
          pharmacyID
          pharmacyIDForSubscription
          recieveID
          shohouData
          isShohouProcessed
          familyUserID
          updatedAt
          createdAt
          answerAt
        }
        nextToken
      }
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      userID
      pharmacyID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
      messages {
        items {
          id
          messageID
          isMember
          isRead
          isQuestionnaire
          stateFlg
          questionnaireJSON
          sendTime
          isAnswer
          imageURL
          content
          pharmacyID
          pharmacyIDForSubscription
          recieveID
          shohouData
          isShohouProcessed
          familyUserID
          updatedAt
          createdAt
          answerAt
        }
        nextToken
      }
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      userID
      pharmacyID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
      messages {
        items {
          id
          messageID
          isMember
          isRead
          isQuestionnaire
          stateFlg
          questionnaireJSON
          sendTime
          isAnswer
          imageURL
          content
          pharmacyID
          pharmacyIDForSubscription
          recieveID
          shohouData
          isShohouProcessed
          familyUserID
          updatedAt
          createdAt
          answerAt
        }
        nextToken
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      messageID
      isMember
      isRead
      isQuestionnaire
      stateFlg
      questionnaireJSON
      sendTime
      isAnswer
      imageURL
      content
      pharmacyID
      pharmacyIDForSubscription
      recieveID
      shohouData
      isShohouProcessed
      familyUserID
      updatedAt
      createdAt
      answerAt
      family {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      messageID
      isMember
      isRead
      isQuestionnaire
      stateFlg
      questionnaireJSON
      sendTime
      isAnswer
      imageURL
      content
      pharmacyID
      pharmacyIDForSubscription
      recieveID
      shohouData
      isShohouProcessed
      familyUserID
      updatedAt
      createdAt
      answerAt
      family {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      messageID
      isMember
      isRead
      isQuestionnaire
      stateFlg
      questionnaireJSON
      sendTime
      isAnswer
      imageURL
      content
      pharmacyID
      pharmacyIDForSubscription
      recieveID
      shohouData
      isShohouProcessed
      familyUserID
      updatedAt
      createdAt
      answerAt
      family {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const createMessageForAndroid = /* GraphQL */ `
  mutation CreateMessageForAndroid(
    $input: CreateMessageForAndroidInput!
    $condition: ModelMessageForAndroidConditionInput
  ) {
    createMessageForAndroid(input: $input, condition: $condition) {
      id
      pharmacyID
      userID
      familyUserID
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageForAndroid = /* GraphQL */ `
  mutation UpdateMessageForAndroid(
    $input: UpdateMessageForAndroidInput!
    $condition: ModelMessageForAndroidConditionInput
  ) {
    updateMessageForAndroid(input: $input, condition: $condition) {
      id
      pharmacyID
      userID
      familyUserID
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageForAndroid = /* GraphQL */ `
  mutation DeleteMessageForAndroid(
    $input: DeleteMessageForAndroidInput!
    $condition: ModelMessageForAndroidConditionInput
  ) {
    deleteMessageForAndroid(input: $input, condition: $condition) {
      id
      pharmacyID
      userID
      familyUserID
      content
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionGroup = /* GraphQL */ `
  mutation CreateQuestionGroup(
    $input: CreateQuestionGroupInput!
    $condition: ModelQuestionGroupConditionInput
  ) {
    createQuestionGroup(input: $input, condition: $condition) {
      id
      name
      updatedAt
      createdAt
      questionJSONs {
        items {
          id
          questionGroupID
          name
          questionnaireJSON
          pharmacyID
          userID
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateQuestionGroup = /* GraphQL */ `
  mutation UpdateQuestionGroup(
    $input: UpdateQuestionGroupInput!
    $condition: ModelQuestionGroupConditionInput
  ) {
    updateQuestionGroup(input: $input, condition: $condition) {
      id
      name
      updatedAt
      createdAt
      questionJSONs {
        items {
          id
          questionGroupID
          name
          questionnaireJSON
          pharmacyID
          userID
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteQuestionGroup = /* GraphQL */ `
  mutation DeleteQuestionGroup(
    $input: DeleteQuestionGroupInput!
    $condition: ModelQuestionGroupConditionInput
  ) {
    deleteQuestionGroup(input: $input, condition: $condition) {
      id
      name
      updatedAt
      createdAt
      questionJSONs {
        items {
          id
          questionGroupID
          name
          questionnaireJSON
          pharmacyID
          userID
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createQuestionJSON = /* GraphQL */ `
  mutation CreateQuestionJSON(
    $input: CreateQuestionJSONInput!
    $condition: ModelQuestionJSONConditionInput
  ) {
    createQuestionJSON(input: $input, condition: $condition) {
      id
      questionGroupID
      name
      questionnaireJSON
      pharmacyID
      userID
      updatedAt
      createdAt
      questionGroup {
        id
        name
        updatedAt
        createdAt
        questionJSONs {
          nextToken
        }
      }
    }
  }
`;
export const updateQuestionJSON = /* GraphQL */ `
  mutation UpdateQuestionJSON(
    $input: UpdateQuestionJSONInput!
    $condition: ModelQuestionJSONConditionInput
  ) {
    updateQuestionJSON(input: $input, condition: $condition) {
      id
      questionGroupID
      name
      questionnaireJSON
      pharmacyID
      userID
      updatedAt
      createdAt
      questionGroup {
        id
        name
        updatedAt
        createdAt
        questionJSONs {
          nextToken
        }
      }
    }
  }
`;
export const deleteQuestionJSON = /* GraphQL */ `
  mutation DeleteQuestionJSON(
    $input: DeleteQuestionJSONInput!
    $condition: ModelQuestionJSONConditionInput
  ) {
    deleteQuestionJSON(input: $input, condition: $condition) {
      id
      questionGroupID
      name
      questionnaireJSON
      pharmacyID
      userID
      updatedAt
      createdAt
      questionGroup {
        id
        name
        updatedAt
        createdAt
        questionJSONs {
          nextToken
        }
      }
    }
  }
`;
export const createQuestionSendSchedule = /* GraphQL */ `
  mutation CreateQuestionSendSchedule(
    $input: CreateQuestionSendScheduleInput!
    $condition: ModelQuestionSendScheduleConditionInput
  ) {
    createQuestionSendSchedule(input: $input, condition: $condition) {
      id
      messageID
      isMember
      isRead
      isQuestionnaire
      stateFlg
      questionnaireJSON
      sendTime
      isAnswer
      imageURL
      content
      pharmacyID
      familyUserID
      recieveID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const updateQuestionSendSchedule = /* GraphQL */ `
  mutation UpdateQuestionSendSchedule(
    $input: UpdateQuestionSendScheduleInput!
    $condition: ModelQuestionSendScheduleConditionInput
  ) {
    updateQuestionSendSchedule(input: $input, condition: $condition) {
      id
      messageID
      isMember
      isRead
      isQuestionnaire
      stateFlg
      questionnaireJSON
      sendTime
      isAnswer
      imageURL
      content
      pharmacyID
      familyUserID
      recieveID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const deleteQuestionSendSchedule = /* GraphQL */ `
  mutation DeleteQuestionSendSchedule(
    $input: DeleteQuestionSendScheduleInput!
    $condition: ModelQuestionSendScheduleConditionInput
  ) {
    deleteQuestionSendSchedule(input: $input, condition: $condition) {
      id
      messageID
      isMember
      isRead
      isQuestionnaire
      stateFlg
      questionnaireJSON
      sendTime
      isAnswer
      imageURL
      content
      pharmacyID
      familyUserID
      recieveID
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const createQuestionRequestFromRakureki = /* GraphQL */ `
  mutation CreateQuestionRequestFromRakureki(
    $input: CreateQuestionRequestFromRakurekiInput!
    $condition: ModelQuestionRequestFromRakurekiConditionInput
  ) {
    createQuestionRequestFromRakureki(input: $input, condition: $condition) {
      id
      windyID
      userID
      pharmacyID
      pharmacistName
      memo
      tags
      scheduleTime
      sendedFlg
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const updateQuestionRequestFromRakureki = /* GraphQL */ `
  mutation UpdateQuestionRequestFromRakureki(
    $input: UpdateQuestionRequestFromRakurekiInput!
    $condition: ModelQuestionRequestFromRakurekiConditionInput
  ) {
    updateQuestionRequestFromRakureki(input: $input, condition: $condition) {
      id
      windyID
      userID
      pharmacyID
      pharmacistName
      memo
      tags
      scheduleTime
      sendedFlg
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const deleteQuestionRequestFromRakureki = /* GraphQL */ `
  mutation DeleteQuestionRequestFromRakureki(
    $input: DeleteQuestionRequestFromRakurekiInput!
    $condition: ModelQuestionRequestFromRakurekiConditionInput
  ) {
    deleteQuestionRequestFromRakureki(input: $input, condition: $condition) {
      id
      windyID
      userID
      pharmacyID
      pharmacistName
      memo
      tags
      scheduleTime
      sendedFlg
      createdAt
      updatedAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const createInformations = /* GraphQL */ `
  mutation CreateInformations(
    $input: CreateInformationsInput!
    $condition: ModelInformationsConditionInput
  ) {
    createInformations(input: $input, condition: $condition) {
      id
      title
      message
      isFromAdmin
      pharmacyID
      userID
      updatedAt
      createdAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const updateInformations = /* GraphQL */ `
  mutation UpdateInformations(
    $input: UpdateInformationsInput!
    $condition: ModelInformationsConditionInput
  ) {
    updateInformations(input: $input, condition: $condition) {
      id
      title
      message
      isFromAdmin
      pharmacyID
      userID
      updatedAt
      createdAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
export const deleteInformations = /* GraphQL */ `
  mutation DeleteInformations(
    $input: DeleteInformationsInput!
    $condition: ModelInformationsConditionInput
  ) {
    deleteInformations(input: $input, condition: $condition) {
      id
      title
      message
      isFromAdmin
      pharmacyID
      userID
      updatedAt
      createdAt
      user {
        id
        loginId
        password
        name
        furigana
        sex
        birthday
        points
        familyIDs
        tags
        fcmToken
        createdAt
        updatedAt
        pharmacies {
          nextToken
        }
        chatRooms {
          nextToken
        }
      }
      pharmacy {
        id
        pharmacyGroupID
        name
        loginId
        loginPassword
        email
        address
        phonenumber
        isPushSound
        helpStartTime
        helpEndTime
        startTimes
        endTimes
        holidays
        pictureURLs
        readInformationsIds
        isGenericDefault
        isTechouDefault
        memo
        createdAt
        updatedAt
        users {
          nextToken
        }
        pharmacyGroup {
          id
          name
          createdAt
          updatedAt
        }
        chatRooms {
          nextToken
        }
      }
    }
  }
`;
