import React from "react";

const Icon = (props) => {
  return (
    <svg
      id="グループ_39"
      data-name="グループ 39"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width || "25.081"}
      height={props.height || "25.081"}
      className={props.className || ""}
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="長方形_61"
            data-name="長方形 61"
            width="24"
            height="24"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="グループ_38" data-name="グループ 38" clipPath="url(#clipPath)">
        <path
          id="パス_53"
          data-name="パス 53"
          d="M21.257,15H16.743a2.892,2.892,0,0,0-2.212.576,1.9,1.9,0,0,0-.525,1.357A8.011,8.011,0,0,1,7.068,9.995a1.908,1.908,0,0,0,1.358-.526A2.9,2.9,0,0,0,9,7.256V2.744A2.9,2.9,0,0,0,8.426.531,1.93,1.93,0,0,0,7,0H3A3,3,0,0,0,0,3V7A17.019,17.019,0,0,0,17,24h4a3,3,0,0,0,3-3V17a1.926,1.926,0,0,0-.531-1.425A2.908,2.908,0,0,0,21.257,15M22,21a1,1,0,0,1-1,1H17A15.017,15.017,0,0,1,2,7V3A1,1,0,0,1,3,2H6.982A6.542,6.542,0,0,1,7,2.727V7.272A6.554,6.554,0,0,1,6.982,8H5V9A10.011,10.011,0,0,0,15,19h1V17.017A7.1,7.1,0,0,1,16.728,17h4.545c.226,0,.539,0,.727.018Z"
          fill={props.color || "#009dad"}
        />
      </g>
    </svg>
  );
};

export default Icon;
