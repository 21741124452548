import React from "react";

const Icon = (props) => {
  const { color, width, height } = props;

  return (
    <svg
      id="グループ_44"
      data-name="グループ 44"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width || "25.416"}
      height={height || "24.655"}
      viewBox="0 0 20 20"
    >
      <g id="グループ_43" data-name="グループ 43" clipPath="url(#clipPath)">
        <path
          id="パス_57"
          data-name="パス 57"
          d="M20,18a7.008,7.008,0,0,0-7-7H7a7.008,7.008,0,0,0-7,7v2H20ZM2,18a5.006,5.006,0,0,1,5-5h6a5.006,5.006,0,0,1,5,5Z"
          fill={color || "#fff"}
        />
        <path
          id="パス_58"
          data-name="パス 58"
          d="M15,5a5,5,0,1,0-5,5,5.006,5.006,0,0,0,5-5M7,5a3,3,0,1,1,3,3A3,3,0,0,1,7,5"
          fill={color || "#fff"}
        />
      </g>
    </svg>
  );
};

export default Icon;
