import React from "react";

const Icon = (props) => {
  const { color, width, height } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width || "30.533"}
      height={height || "30.034"}
      viewBox="0 0 30.533 30.034"
    >
      <g id="グループ_37" data-name="グループ 37" transform="translate(0 0)">
        <g id="グループ_36" data-name="グループ 36">
          <path
            id="パス_21"
            data-name="パス 21"
            d="M5.48,23.271a5.479,5.479,0,0,1-3.875-9.354L13.916,1.606a5.48,5.48,0,1,1,7.75,7.749L9.354,21.667a5.442,5.442,0,0,1-3.874,1.6M17.792,2A3.458,3.458,0,0,0,15.33,3.02L3.019,15.331a3.486,3.486,0,0,0,0,4.922,3.563,3.563,0,0,0,4.921,0L20.252,7.941A3.481,3.481,0,0,0,17.792,2"
            fill={color || "#fff"}
          />
          <path
            id="パス_22"
            data-name="パス 22"
            d="M14.8,14.8,20.96,8.648a4.48,4.48,0,0,0-6.336-6.335L8.468,8.469Z"
            fill={color || "#fff"}
          />
          <path
            id="パス_23"
            data-name="パス 23"
            d="M27.549,15.436A8.247,8.247,0,0,0,15.936,27.049Z"
            fill={color || "#fff"}
          />
          <path
            id="パス_24"
            data-name="パス 24"
            d="M28.634,16.522,17.021,28.135A8.247,8.247,0,0,0,28.634,16.522"
            fill={color || "#fff"}
          />
        </g>
      </g>
    </svg>
  );
};

export default Icon;
