import React from "react";

const Icon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width || "15.988"}
      height={props.height || "21.53"}
      viewBox="0 0 15.988 21.53"
    >
      <g id="グループ_55" data-name="グループ 55" transform="translate(0 0)">
        <g id="グループ_54" data-name="グループ 54" clipPath="url(#clipPath)">
          <path
            id="パス_71"
            data-name="パス 71"
            d="M7.994,0A8,8,0,0,0,0,7.994C0,12.11,6.495,19.785,7.235,20.647l.759.883.759-.883c.74-.862,7.235-8.537,7.235-12.653A8,8,0,0,0,7.994,0m0,18.433C5.813,15.749,2,10.529,2,7.994a5.994,5.994,0,0,1,11.988,0c0,2.532-3.813,7.755-5.994,10.439"
            fill={props.color || "#009dad"}
          />
          <path
            id="パス_72"
            data-name="パス 72"
            d="M7.976,3.964a4,4,0,1,0,4,4,4,4,0,0,0-4-4m0,6a2,2,0,1,1,2-2,2,2,0,0,1-2,2"
            fill={props.color || "#009dad"}
          />
        </g>
      </g>
    </svg>
  );
};

export default Icon;
