import React from "react";
import { useZxing } from "react-zxing";

const QRCodeReader = (props) => {
  const { qrReaderCallback } = props;

  const { ref } = useZxing({
    onResult(result) {
      console.log(result);
      qrReaderCallback(result.getText());
    },
  });

  return (
    <div className="qrCodeWrapper">
      <video ref={ref} style={{ width: "100%" }} />
      <p className="caution">QRコードに向けてください</p>
    </div>
  );
};

export default QRCodeReader;
