import React from "react";

// Users
const UserHome = React.lazy(() => import("../pages/User/UserHome"));
const UserChat = React.lazy(() => import("../pages/User/UserChat"));
const UserFamilySetting = React.lazy(() =>
  import("../pages/User/UserFamilySetting")
);
const UserNews = React.lazy(() => import("../pages/User/UserNews"));
const UserPharmacies = React.lazy(() => import("../pages/User/UserPharmacies"));
const UserPrescriptionHistory = React.lazy(() =>
  import("../pages/User/UserPrescriptionHistory")
);
const UserSetting = React.lazy(() => import("../pages/User/UserSetting"));
const PrivacyPolicy = React.lazy(() => import("../pages/User/PrivacyPolicy"));

const routes = [
  {
    path: "/",
    exact: true,
    name: "UserHome",
    component: UserHome,
  },
  {
    path: "/chat/:id",
    exact: true,
    name: "UserChat",
    component: UserChat,
  },
  {
    path: "/familySetting",
    exact: true,
    name: "UserFamilySetting",
    component: UserFamilySetting,
  },
  {
    path: "/news/:type",
    exact: true,
    name: "UserNews",
    component: UserNews,
  },
  {
    path: "/pharmacies",
    exact: true,
    name: "UserPharmacies",
    component: UserPharmacies,
  },
  {
    path: "/prescriptionHistory",
    exact: true,
    name: "UserPrescriptionHistory",
    component: UserPrescriptionHistory,
  },
  {
    path: "/setting",
    exact: true,
    name: "UserSetting",
    component: UserSetting,
  },
  {
    path: "/privacyPolicy",
    exact: true,
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

export default routes;
